<template>
    <div class="col-12 col-lg-6 g c">
        <div class="card card-body">
            <div class="form-group">
              <label for="">وقت بدء المناداة</label>
              <input type="time"
                class="form-control" v-model="settings.calls_start">
            </div>
            <div class="form-group">
              <label for="">وقت انتهاء المناداة</label>
              <input type="time"
                class="form-control" v-model="settings.calls_end">
            </div>
            <div class="form-group">
              <label for="">المدة بين كل مناداة</label>
              <select class="form-control" v-model="settings.calls_timer">
                <option value="0">لا يوجد</option>
                <option value="1">١ دقيقة</option>
                <option value="5">٥ دقائق</option>
                <option value="10">١٠ دقائق</option>
              </select>
            </div>
            <div class="form-check">
              <span>
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="settings.calls_noaccept" :value="true">
                    السماح للجميع بالمناداة (لا يستلتزم انشاء طلب تفعيل مناداة ليتمكن من المناداة)
                </label>
              </span>
            </div>
            <div class="form-check">
              <span>
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="settings.calls_voice" :value="true">
                    نطق اسم الطالب عند المناداة
                </label>
              </span>
            </div>
            <div class="col-12 text-center g">
                <br>
                <button class="btn btn-primary" @click="save()">
                    حفظ
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            settings: {}
        }
    },
    created(){
        if(!checkPer("calls")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/calls/settings', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.settings = r.response
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200)
        })
    },
    methods: {
        save(){
            var g = this;
            var v = g.settings
            g.settings.jwt = this.user.jwt
            $.post(api + '/user/calls/settings/save', v).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم الحفظ", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style>

</style>